/* eslint-disable max-len */
import React from 'react'
// import Button from '../../UI/Button'
import { ASSETS } from '../../Theme'

const Maintenance = React.memo(() => {
  return (
    <div style={sytles.container}>
      <div className="bannerRightContent">
        <img src={ASSETS.MAINTENANCE} alt="banner" className="bannerImg" />
      </div>
      <div style={{
        width: '60%', textAlign: 'center', marginTop: 100, flexDirection: 'column', alignItems: 'center', display: 'flex',
      }}
      >
        <h1 className="bannerTitle">
          We’re under maintenance
        </h1>
        <h4 className="bannerSubTitle" style={{ color: '#A1A5BA', marginTop: 6 }}>
          The Streak website will not be accessible from 4:30 PM to 8:30 PM today, 30th of Aug 2024 due to a scheduled maintenance. We regret the inconvenience caused and appreciate your cooperation.
        </h4>
      </div>
    </div>
  )
})

const sytles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    height: '100%',
    padding: 30,
    backgroundColor: 'white',
  },
}

export default Maintenance
