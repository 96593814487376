import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import store from './store'
// import App from './App'
import reportWebVitals from './reportWebVitals'

import ThemeProvider from './Theme/ThemeProvider'
import './index.css'
import 'react-circular-progressbar/dist/styles.css'
import Maintenance from './containers/Maintenance/Maintenance'

Sentry.init({
  dsn: 'https://c08af936856c4436a3ce95485003016e@log-sentry.streak.ninja/7',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <React.StrictMode>
        <ThemeProvider>
          <HelmetProvider>
            {/* <App /> */}
            <Maintenance />
          </HelmetProvider>
        </ThemeProvider>
      </React.StrictMode>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
